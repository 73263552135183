<template>
    <div class="members">
        <div v-if="show1">
            <div class="fx" style="border-bottom:1px solid #ccc;padding-bottom:10px;">
                <div>会员证</div>
            </div>
            <div class="btnmy" @click="show1 = false">返回</div>
            <div style="display:flex;justify-content: center;position: relative;">
                <span class="span1" style="right: 308px;top: 93px;">{{ MembershiPcertificateObj.card_no }}</span>
                <span class="span" style="right: 142px;top: 360px;">{{ MembershiPcertificateObj.nation }}</span>
                <span class="span" style="right: 142px;top: 240px;">{{ MembershiPcertificateObj.sex == 1 ? '男' : '女'
                }}</span>
                <span class="span" style="right: 197px;top:240px;">{{ MembershiPcertificateObj.location }}</span>
                <span class="span" style="right: 90px;top: 240px;">{{ MembershiPcertificateObj.name }}</span>
                <span class="span1" style="right: 253px;top: 93px;">{{ MembershiPcertificateObj.member_id }}</span>
                <span class="span" style="right: 252px;top: 240px;">{{ MembershiPcertificateObj.company }}</span>
                <span class="span1" style="right: 360px;top: 93px;">
                    <span class="myspan" style="position: relative;top: 25px;right: -13px;">
                        <span> {{ MembershiPcertificateObj.vip_end_at_arr[0] }}</span>
                        <span> {{ MembershiPcertificateObj.vip_end_at_arr[1] }}</span>
                        <span> {{ MembershiPcertificateObj.vip_end_at_arr[2] }}</span>
                    </span>
                    <span class="myspan"
                        style="position: relative;top: 25px;right: -10px;width: 5px;display: inline-block;">
                        <span> {{ MembershiPcertificateObj.verify_at_arr[0] }}</span>
                        <span> {{ MembershiPcertificateObj.verify_at_arr[1] }}</span>
                        <span> {{ MembershiPcertificateObj.verify_at_arr[2] }}</span>
                    </span>
                </span>
                <span class="span1" style="right: 80px;top: 10px;width: 146px;">
                    <!-- <img :src="MembershiPcertificateObj.photo" alt=""> -->
                    <img width="100%" :src="MembershiPcertificateObj.photo" alt="">
                </span>

                <img src="../../../public/image/56ff62579d077d3b384e24eb021ea8c.png" alt="">
            </div>
        </div>
        <div v-else>
            <div class="fx" style="border-bottom:1px solid #ccc;padding-bottom:10px;">
                <div>会员申请</div>
            </div>
            <div v-if="memberList.order_sn">
                <!-- <h3>会员申请</h3> -->
                <div class="fx">
                    <div v-for="(item, i) in stepList" :key="i">
                        <div v-if="item.status == memberList.status" class="fx">
                            <div class="boxs">
                                <img :src="item.img" alt="">
                                <div :style="{ 'color': item.color }">{{ item.name }}</div>
                                <div class="desc" v-if="item.type">{{ item.desc + '中国书画文化发展促进会产业专业委员会' }}</div>
                                <div class="desc" v-else-if="item.ref">{{ item.desc + memberList.reason }}</div>
                                <div class="desc" v-else>{{ item.desc }}</div>
                                <div style="display: flex;">
                                    <!-- <div class="btn" v-if="item.btn" @click="Jump(memberList, item.btn)">{{ item.btn }}
                                    </div>
                                    <div class="btn" v-if="item.btn2" @click="MembershipCertificate(memberList.id)">{{
                                        item.btn2 }}
                                    </div> -->
                                    <div v-if="item.name == '待审核'" style="margin: 0 auto;">
                                        <div :style="{ 'color': item.color }">{{ item.name }}</div>
                                    </div>
                                    <div style="margin: 0 auto;" v-else>
                                        <div  class="btn" v-if="item.btn" @click="Jump(memberList, item.btn)">{{ item.btn }}
                                        </div>
                                        <div   class="btn" v-if="item.btn2" @click="MembershipCertificate(memberList.id)">{{
                                            item.btn2 }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <img v-if="item.imgs" :src="item.imgs" alt="" style="margin:0px 10px;">
                        </div>
                    </div>
                </div>
            </div>
            <div class="t-center" style="padding:40px 0px;" v-else>
                <img src="shexie/img/nodata.png" alt="">
                <div>暂无数据</div>
            </div>
            <div class="yingyin" v-if="show">
                <div class="yboxs">
                    <div class="fx">
                        <div>支付</div>
                        <i class="el-icon-close cursor" @click="show = false, destoryInter()"></i>
                    </div>
                    <div class="t-center" style="margin:30px 0px;" v-if="status == 1">
                        <div style="color:#CC0000;margin-bottom:10px;">支付会费{{ memberList.amount }}元</div>
                        <span>剩余支付时间：{{ interTime }}秒</span>
                        <div class="codeimg">
                            <div class="qrcode" ref="qrCodeUrl"></div>
                            <!-- <img :src="wechatCode.result.code_url" alt=""> -->
                        </div>
                        <img src="shexie/img/Scan_code.png" alt="">
                    </div>
                    <div class="t-center" v-if="status == 2" style="margin-top:60px;">
                        <img src="shexie/img/success.png" alt="">
                        <div style="margin:20px 0px;">
                            <div>您已成功支付会费</div>
                        </div>
                        <div class="btns" style="margin:auto;" @click="show = false">确定</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import { memberList, committee_renew } from '@/api/member'
import { wechat_pay, payment_order, getMembershiPcertificate } from '@/api/order'
export default {
    data() {
        return {
            stepList: [
                { img: 'shexie/img/tj.png', name: '已提交', type: 1, desc: '申请加入', btn: '修改信息', color: "#CC0000", status: 0, imgs: 'shexie/img/right.png' },
                { img: 'shexie/img/zf1.png', name: '待审核', desc: '提交成功待审核!', btn: '待审核', color: "#666", status: 0, imgs: 'shexie/img/right1.png' },
                { img: 'shexie/img/sh1.png', name: '未审核', desc: '等待人工审核身份信息', color: "#666", status: 0, imgs: 'shexie/img/right1.png' },
                { img: 'shexie/img/jr1.png', name: '未加入', type: 1, desc: '未加入', color: "#666", status: 0 },

                { img: 'shexie/img/tj.png', name: '已提交', type: 1, desc: '申请加入', color: "#CC0000", status: 1, imgs: 'shexie/img/right.png' },
                { img: 'shexie/img/zf.png', name: '已支付', desc: '已支付入会费用', color: "#CC0000", status: 1, imgs: 'shexie/img/right.png' },
                { img: 'shexie/img/sh1.png', name: '审核中', desc: '正在人工审核身份信息', color: "#666", status: 1, imgs: 'shexie/img/right1.png' },
                { img: 'shexie/img/jr1.png', name: '未加入', type: 1, desc: '未加入', color: "#666", status: 1 },

                { img: 'shexie/img/tj.png', name: '已提交', type: 1, desc: '申请加入', color: "#CC0000", status: 3, imgs: 'shexie/img/right.png' },
                { img: 'shexie/img/zf.png', name: '已支付', desc: '已支付入会费用', color: "#CC0000", status: 3, imgs: 'shexie/img/right.png' },
                { img: 'shexie/img/sh.png', name: '审核通过', desc: '您已通过人工审核身份信息', color: "#CC0000", status: 3, imgs: 'shexie/img/right.png' },
                { img: 'shexie/img/jr.png', name: '已加入', type: 1, desc: '您已成功加入', color: "#CC0000", status: 3, btn: '续费', btn2: '会员证' },

                { img: 'shexie/img/tj.png', name: '已提交', type: 1, desc: '申请加入', color: "#CC0000", status: 2, imgs: 'shexie/img/right.png' },
                { img: 'shexie/img/zf.png', name: '已支付', desc: '已支付入会费用', color: "#CC0000", status: 2, imgs: 'shexie/img/right.png' },
                { img: 'shexie/img/sh1.png', name: '已驳回', desc: '驳回原因：', ref: '1', btn: '重新提交', color: "#666", status: 2, imgs: 'shexie/img/right1.png' },
                { img: 'shexie/img/jr1.png', name: '未加入', type: 1, desc: '未加入', color: "#666", status: 2 },
            ],
            memberList: {},
            wechatCode: {},
            status: 1,
            show: false,
            show1: false,
            inter: null,
            interTime: 60,
            isSuccess: false,
            orderId: {},
            MembershiPcertificateObj: {}
        };
    },
    created() {
        this.getMember()
    },
    mounted() {

    },
    methods: {
        getMember() {
            memberList().then(res => {
                if (res.code == 200) {
                    this.memberList = res.data
                }
            })
        },
        Jump(items, name) {
            if (name == '修改信息') {
                // if(items.rank.identifier=='member'){
                this.$router.push({ path: '/memberIndex', query: { id: items.apply_id } })
                // }else{
                //     this.$router.push({path:'/leochIndex',query:{id:items.apply_id}})
                // }
            } else if (name == '去支付') {
                this.show = true
                this.getWechat()
            } else if (name == '重新提交') {
                // if(items.rank.identifier=='member'){
                this.$router.push({ path: '/memberIndex', query: { id: items.apply_id } })
                // }else{
                //     this.$router.push({path:'/leochIndex',query:{id:items.apply_id}})
                // }
            } else if (name == '去续费') {
                this.show = true
                this.getrenew()
            }
        },
        getrenew() {
            committee_renew().then(res => {
                if (res.code == 200) {
                    this.memberList = res.data
                    this.getWechat()
                }
            })
        },
        getWechat() {
            wechat_pay(this.memberList.id, { from: 'pc' }).then(res => {
                if (res.code == 200) {
                    var qrcode = new QRCode(this.$refs.qrCodeUrl, {
                        text: res.data.result.code_url, // 需要转换为二维码的内容
                        width: 200,
                        height: 200,
                        colorDark: '#000000',
                        colorLight: '#ffffff',
                        correctLevel: QRCode.CorrectLevel.H
                    })
                    this.wechatCode = res.data
                    this.startInter()
                }
            })
        },
        //判断有没有支付完成
        startInter() {
            this.destoryInter();
            this.inter = setInterval(() => {
                --this.interTime;
                if (this.interTime % 2 == 0) {
                    this.orderCheck();
                }
                if (this.interTime < 1) {
                    this.destoryInter();
                    //支付时间已过;
                }
            }, 1000);
        },
        destoryInter() {
            if (this.inter) {
                clearInterval(this.inter);
                this.inter = null;
                this.interTime = 60;
                this.show = false
            }
        },
        MembershipCertificate(val, val1) {
            //   console.log(val,val1)  
            getMembershiPcertificate().then(res => {
                if (res.code == 200) {
                    console.log(res)
                    this.MembershiPcertificateObj = res.data
                    this.MembershiPcertificateObj.verify_at_arr = this.MembershiPcertificateObj.verify_at.slice(0, 10).split('-')
                    this.MembershiPcertificateObj.vip_end_at_arr = this.MembershiPcertificateObj.vip_end_at.slice(0, 10).split('-')
                    this.show1 = true
                    console.log()
                }
            })
        },
        //是否成功
        orderCheck() {
            payment_order({ order_sn: this.wechatCode.order_sn }).then(res => {
                if (res.code == 200) {
                    this.$message.success("支付成功!");
                    this.isSuccess = true;
                    this.status = 2
                    clearInterval(this.inter);
                }
            })
        },
    }
};
</script>

<style scoped lang="scss">
.members {
    .boxs {
        height: 208px;
        width: 148px;
        padding: 15px;
        text-align: center;
        border: 1px solid#ccc;
        border-radius: 4px;

        .desc {
            margin: 10px 0px 15px;
            font-size: 14px;
            color: #666;
        }

        .btn {
            display: inline-block;
            padding: 2px 10px;
            color: #CD0000;
            margin: auto;
            cursor: pointer;
            text-align: center;
            border: 1px solid #FF6666;
            border-radius: 4px;
        }

        img {
            margin: 10px auto 15px;
        }
    }

    .yingyin {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(1, 1, 1, .4);
        z-index: 99;

        .yboxs {
            width: 480px;
            height: 474px;
            background: #FFFFFF;
            border-radius: 10px;

            .fx {
                padding: 10px 30px;
                background: #F5F5F5;

                .cursor {
                    font-size: 24px;
                }
            }

            .codeimg {
                margin: 20px 0px;

                img {
                    padding: 10px;
                    border: 1px solid #ccc;
                    width: 200px;
                    height: 200px;
                }

                .qrcode {
                    padding: 10px;
                    border: 1px solid #ccc;
                    width: 200px;
                    height: 200px;
                    margin: auto;
                }
            }

            .btns {
                width: 200px;
                height: 48px;
                color: #fff;
                text-align: center;
                line-height: 48px;
                background: #CC0000;
                border-radius: 24px;
                cursor: pointer;
            }
        }
    }
}

.btnmy {
    display: inline-block;
    padding: 2px 10px;
    color: #CD0000;
    margin: auto;
    cursor: pointer;
    text-align: center;
    border: 1px solid #FF6666;
    border-radius: 4px;
    margin-top: 30px;
    margin-bottom: 50px;
}

.span {
    writing-mode: vertical-rl;
    position: absolute;
    font-size: 16px;
    font-weight: bold;
    // font-family: 'Courier New', Courier, monospace;
}

.myspan>span {
    margin-bottom: 40px;
}

.span1 {
    writing-mode: vertical-rl;
    position: absolute;
    font-size: 18px;
    font-weight: bold;
    // font-family: 'Courier New', Courier, monospace;
}
</style>
